












































import { Component, Prop, Vue } from 'vue-property-decorator'
import { BigNumber } from 'ethers'
import { VARIABLES } from '@/variables'

@Component({
  name: 'TransactionIndicator'
})
export default class TransactionIndicator extends Vue {
  showIndicator = false

  errorMessage: string | null = null
  url = VARIABLES.ETHERSCAN_URL

  @Prop({
    required: false // because of the Validators.vue use-case
  })
  // @ts-ignore
  transactionFunction: () => Promise<void>

  setTransactionFunction (fn: () => Promise<void>): void {
    this.transactionFunction = fn
  }

  onClick () {
    this.showIndicator = false
    this.errorMessage = null
  }

  async callTransactionFunction (): Promise<void> {
    let error = false
    this.showIndicator = true
    try {
      await this.transactionFunction()
    } catch (e) {
      if (!e.code || e.code !== 4001) {
        if (e.error && e.error.message) {
          if ((e.error.message as string).indexOf('ERC20: transfer amount exceeds allowance') !== -1) {
            console.error('possible internal error with cached approved tokens, will reset them')
            localStorage.setItem('approvedTokens', '{}')
          }
          this.errorMessage = this.replaceErrorPlaceholders(e.error.message)
          this.errorMessage = this.errorMessage?.charAt(0).toUpperCase() + (this.errorMessage?.slice(1) || '')
        } else if (e.code && e.code === 'CALL_EXCEPTION' && this.isErrorOutOfGas(e)) {
          this.errorMessage = '<p>Transaction was reverted because it ran out of gas. Please increase the gas limit or try again later.</p>' +
            `<p>You can check your transaction at <a href="${this.url}/tx/${e.transactionHash}" target="_blank">${e.transactionHash}</a></p>`
        } else {
          this.errorMessage = '<p>You transaction was reverted.</p>' +
                        '<p>Please try again later or reach out to a team member on Discord for additional assistance</p><p>Discord link - <a href="https://discord.gg/jbygBjGPWA">https://discord.gg/jbygBjGPWA</a></p>' +
                        `<p>You can check your transaction at <a href="${this.url}/tx/${e.transactionHash}" target="_blank">${e.transactionHash}</a></p>` +
                        `<p>Full stacktrace: ${JSON.stringify(e)}</p>`
        }
        error = true
      }
      console.log(e)
    }
    this.showIndicator = error
  }

  isErrorOutOfGas (error: any): boolean {
    let gasLimit = null
    let gasUsed = null
    if (error.transaction && error.transaction.gasLimit) {
      gasLimit = BigNumber.from(error.transaction.gasLimit)
    }
    if (error.receipt && error.receipt.gasUsed) {
      gasUsed = BigNumber.from(error.receipt.gasUsed)
    }

    return gasLimit !== null && gasUsed != null && (gasLimit.sub(gasUsed)).lte(BigNumber.from(1000))
  }

  replaceErrorPlaceholders (errorMsg: string): string {
    const replacements: any = {
      SPI1: 'This function can only be called once, and has already been called',
      SPI2: 'Service Provider cannot be zero address',
      SPI3: 'Cudos token cannot be zero address',
      SPF1: 'Fee percentage must be greater or equal to the minimum Service Provider fee',
      SPC1: 'Only Service Provider',
      SPC2: 'Service Provider not setup',
      SPC3: 'Only Service Provider manager',
      SPC4: 'Not a Service Provider method',
      SPHL: 'Service Provider has left',
      SPC6: 'Amount cannot be 0',
      SPC7: 'Service Provider already set up',
      ECR1: 'Exited Service Provider cannot reenter',
      FP2: 'Fee percentage must be between zero and 100%',
      SPU1: 'Unknown Service Provider',
      CNB0: 'Amount cannot be 0',
      PSD: 'Contract is paused',
      OWL: 'Only whitelisted',
      OA: 'Only admin',
      SPW3: 'Unbonding period has not yet passed',
      SRW1: 'Amount exceeds balance',
      SPW5: 'Minimum staking period has not yet passed',
      IAP: 'Invalid allocation point',
      PAA: 'Programme is already active',
      SPS1: 'Exceeds maximum staking allowed for a Service Provider',
      SPE1: 'Service Provider has not exited',
      SPW1: 'No pending withdrawal',
      SPW2: 'No withdrawal request in flight',
      SPW4: 'Amount exceeds delegated stake',
      SPW7: 'Remaining stake for a Service Provider cannot be under the minimum value',
      OASM: 'Only authorised smart contract',
      SRG1: 'Recipient is zero address'
    }

    for (const key in replacements) {
      errorMsg = errorMsg.replace(new RegExp(` ${key}$`), ' ' + replacements[key])
    }
    return errorMsg
  }
}
