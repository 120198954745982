



























import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component({ name: 'SuccessPage' })
export default class extends Vue {
  @Prop({ default: 'Success!' })
  title!: string

  @Prop()
  subtitle!: string

  @Prop({ required: true })
  backToPath!: string

  @Prop({ required: true })
  backToLabel!: string

  @Prop({ default: false })
  loaded!: boolean

  handleSuccess (): void {
    window.location.replace(this.backToPath)
  }
}
